import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $ from 'jquery'
import JwPagination from 'jw-vue-pagination'

require("dotenv").config();

Vue.config.productionTip = false

// axios
import axios from "axios"
Vue.prototype.$http = axios
Vue.prototype.$jquery = $

Vue.component('jw-pagination', JwPagination)

var vm = new Vue({
  directives: {
    focus: {
      // 연결되어 있는 요소가 DOM에 추가될 때
      inserted: function (el) {
        el.focus() // 요소에 초점을 맞춤
      }
    }
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')

window.app = vm;