<template>
  <div class="home">

    <HeaderComponent></HeaderComponent>
    <SidemenuComponent></SidemenuComponent>
    <router-view class="web_main"/>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderComponent from '@/components/common/Header.vue'
import SidemenuComponent from '@/components/common/Sidemenu.vue'
import FooterComponent from '@/components/common/Footer.vue'

export default {
  name: 'Home',
  components: {
    HeaderComponent,
    SidemenuComponent,
    FooterComponent,
  }
}
</script>
<style scoped>
.web_main {
  min-height: calc(100vh - 300px);
}
@media (max-width: 1200px){
  .web_main {
    min-height: calc(100vh - 293px);
  }
}
</style>
