<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default{
    mounted() {
        this.music_MP3();
    },
    methods: {
        music_MP3() {      
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/home/music`, {}
            ).then(
                res => {
                    if(res.status == 200) {
                        const music = res.data.info;
                        this.$store.commit("setMusic", music)
                    }
                }
            )
        },
    },
}
</script>

<style>
@font-face {
    font-family: 'Noto Sans KR';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2201-2@1.0/Noto Sans KR.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
a, a:hover{
    text-decoration: none !important;
    color: inherit;
}
.App{
  font-family: Noto Sans KR;  
}

.w-100{
    width: 100%;
}
/* title tab */
.sub-tit {
    margin: 100px 0 30px;
}
.sub-tit span {
    display: block;
    text-align: center;
    margin-top: 20px;
    font-size: 20px;
    color: #000000;
    font-weight: 300;
    letter-spacing: -0.02em;
    line-height: 1em;
}
.portfolio-wrap h3 {
    display: block;
    text-align: center;
    font-family: Noto Sans KR;
    font-weight: 500;
    font-size: 50px;
    color: #000;
    line-height: 1em;
}
.portfolio-wrap .tab-tab {
    text-align: center;
    margin-bottom: 40px;
}
.portfolio-wrap .tab-tab li {
    display: inline-block;
    zoom: 1;
    *display: inline;
    vertical-align: top;
    margin: 5px;
}
.portfolio-wrap .tab-tab li a{
    display: block;
    height: 55px;
    line-height: 55px;
    padding: 0 30px;
    background: #f7f7f7;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    text-decoration: none;
}
.portfolio-wrap .tab-tab li.active a {
    /* background: #000; */
    background: #47a6b9;
    color: #fff;
    box-shadow: 0 0 15px rgb(0 0 0 / 40%);
}

.sub-vis{
    position: relative;
    height: 300px;
    overflow: hidden;
    text-align: center;
    background-position: center center;
    background-size: cover;
}

#sb-footer i{margin: 0 5px;}

@media screen and (max-width: 1200px) {
    .portfolio-wrap .tab-tab li a {
        height: 35px;
        line-height: 35px;
        width: 100px;
        font-size: 12px;
    }
}

/* lnb */
.lnbWrap{
    position: relative; 
    /* left: -30px; */
}
.lnbWrap .depth02{
    transition: all .3s;
    font-size: 12px;
    padding: 10px;
}
.lnbWrap .depth02 a{
    font-size: 14px !important;
}
#lnb {
    width: 200px;
    margin: 0;
    text-align: right;
    display: flex;
    flex-direction: column;
    padding: 5px 0 80px;
    border-right: 1px solid #ddd;
    box-sizing: border-box;
}
#lnb>li{
    margin: 0;
    border-bottom: 1px solid #ddd;
}
#lnb>li:last-child{
    border-bottom: none;
}
#lnb>li.active>a,
#lnb>li>a{
    box-shadow: none !important;
    font-size: 18px;
    padding: 0 10px;
    background-color: transparent;
}
#lnb>li.active>a{
    font-weight: bold;
    color: #333;
    box-sizing: border-box;
    border-right: 3px solid #8ac9da;
    background: #8ac9da17;
}
#lnb>li>a.router-link-exact-active{
    font-weight: 700;
    color: #333;
    box-sizing: border-box;
    border-right: 3px solid #8ac9da;
    background: rgba(138,201,218,.09019607843137255);
}
.prdt-list2{
    width: calc(100% - 250px);
    padding-left: 35px;
}
.prdt-list2.w-100{
    width: 100%;
}
@media screen and (max-width:1200px) {
    .prdt-list2{
        width: 100%;
        padding-left: 0;
    }
}
.main-banner>span{
    text-shadow: 0px 0px 20px #000;
    letter-spacing: 3px;
}

</style>
