import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    api: process.env.VUE_APP_API_URL,
    AWS: {
      URL: process.env.VUE_APP_AWSURL,
      albumBucketName: process.env.VUE_APP_AWSBUCKETNAME,
      bucketRegion: process.env.VUE_APP_AWSBUCKETREGION,
      IdentityPoolId: process.env.VUE_APP_AWSPOOLID,
    },
    music: ''
  },
  plugins: [createPersistedState()],
  mutations: {
    setMusic(state, music){
      state.music = music;
    }
  },
  actions: {
  },
  modules: {
  }
})
