import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
const MainIndex = () => import( /* webpackChunkName: "sign" */ '../components/main/index.vue')
const About = () => import( /* webpackChunkName: "company" */ '../components/company/About.vue')
const Ceo = () => import( /* webpackChunkName: "company" */ '../components/company/Ceo.vue')

const Product = () => import( /* webpackChunkName: "sign" */ '../components/main/product.vue')
const ProductDetail = () => import( /* webpackChunkName: "sign" */ '../components/main/product_detail.vue')
const Portfolio = () => import( /* webpackChunkName: "sign" */ '../components/main/portfolio.vue')
const PortfolioDetail = () => import( /* webpackChunkName: "Portfolio" */ '../components/main/Detail.vue')

const Partners = () => import( /* webpackChunkName: "sign" */ '../components/main/Partners.vue')
const PartnersDetail = () => import( /* webpackChunkName: "Portfolio" */ '../components/main/partner_detail.vue')
const Guide = () => import( /* webpackChunkName: "sign" */ '../components/main/Guide.vue')

const Faq = () => import( /* webpackChunkName: "contacts" */ '../components/contacts/faq.vue')
const Qna = () => import( /* webpackChunkName: "contacts" */ '../components/contacts/qna.vue')
const QnaWriter = () => import( /* webpackChunkName: "contacts" */ '../components/contacts/qna_writer.vue')
const QnaDetail = () => import( /* webpackChunkName: "contacts" */ '../components/contacts/qna_detail.vue')

const PromoEvent = () => import( /* webpackChunkName: "promotion" */ '../components/promotion/Event.vue')
const PromoEventDetail = () => import( /* webpackChunkName: "promotion" */ '../components/promotion/EventDetail.vue')
const PromoWinner = () => import( /* webpackChunkName: "promotion" */ '../components/promotion/Winner.vue')
const PromoBenefit = () => import( /* webpackChunkName: "promotion" */ '../components/promotion/Benefit.vue')

const Term = () => import( /* webpackChunkName: "contacts" */ '../components/main/term.vue')
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    redirect: '/main',
    alias: '/main',
    children: [
      {
        path: '/main',
        name: 'MainIndex',
        component: MainIndex
      },
      {
        path:'/company/aboutus',
        name:'About',
        component: About
      },
      {
        path:'/company/ceo',
        name:'Ceo',
        component: Ceo
      },
      {
        path:'/product/:idx',
        name:'Product',
        component: Product
      },
      {
        path: '/product/:idx/:post',
        name: 'Produce_detail',
        component: ProductDetail
      },
      {
        path:'/portfolio/:idx',
        name:'Portfolio',
        component: Portfolio
      },
      {
        path:'/portfolio/:idx/:post',
        name:'Portfolio_detail',
        component: PortfolioDetail
      }, 
      {
        path: '/company/partners',
        name: 'Partners',
        component: Partners
      }, 
      {
        path: '/company/partners/:post',
        name: 'Partners_detail',
        component: PartnersDetail
      },
      {
        path: '/company/guide',
        name: 'Guide',
        component: Guide
      },
      {
        path:'/contacts/faq',
        name:'FAQ',
        component: Faq
      },
      {
        path:'/contacts/qna',
        name:'QNA',
        component: Qna
      },
      {
        path:'/contacts/qna/writer',
        name:'QNAWriter',
        component: QnaWriter
      },
      {
        path:'/contacts/qna/:idx',
        name:'QnaDetail',
        component: QnaDetail
      },
      {
        path:'/promotion/event/:type',
        name:'PromoEvent',
        component: PromoEvent
      },
      {
        path:'/promotion/event/:type/article/:no',
        name:'PromoEventDetail',
        component: PromoEventDetail
      },
      {
        path:'/promotion/winner',
        name:'PromoWinner',
        component: PromoWinner
      },
      {
        path:'/promotion/benefit',
        name:'PromoBenefit',
        component: PromoBenefit
      },
      {
        path:'/term/:idx',
        name:'Term',
        component: Term
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

export default router
