<template>
<header id="header">
	<div class="inner">
		<!-- logo -->
		<h1 class="logo">
            <router-link to="/">
                <img class="logo-img">
            </router-link>
        </h1>
		<!-- gnb -->
		<ul id="gnb">
            <li v-for="(item, index) in main" :key="index" :class="{'hidden-gnb': item.hidden == 'Y'}">
                <router-link :to="item.site">{{item.title}}</router-link>
                <ul>
                    <li v-for="(jtem, key) in sub" :key="key">
                        <router-link :to="jtem.content" v-if="jtem.h_no == item.no">
                            {{jtem.title}}
                        </router-link>
                    </li>
                </ul>
            </li>
            
            <!-- <li>
                <router-link to="/promotion/event/event">
                    프로모션
                </router-link>
                <ul>
                    
                    <li>
                        <router-link to="/promotion/event/event">
                            진행중인 이벤트
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/promotion/event/finish">
                            종료된 이벤트
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/promotion/winner">
                            당첨자 발표
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/promotion/benefit">
                            제휴혜택
                        </router-link>
                    </li>
                </ul>
            </li> -->
		</ul>

        <ul id="sns-ico">
            <li v-for="(item, index) in sns" :key="index">
                <a class="icon-header" :href="item.site" target="_blank">
                <img :src="AWSURL + item.img"></a>
            </li>                                                  
        </ul>
        <ul class="sound-ul">              
            <li>
                <a  class="icon-header" href="javascript:;" @click="isPlaying? music_Stop() : music_Play()" allow="autoplay">
                    <i class="fal" :class="{'fa-volume' : isPlaying,'fa-volume-slash': !isPlaying}"></i>
                </a>
            </li>     
        </ul>
		<!-- slide button -->
        <div id="slide-btn" @click="slideOpenFn()">
			<button><span></span></button>
			Open menu
		</div>
	</div>
</header>
</template>
<script>
export default {
    data(){
        return{
            AWSURL: this.$store.state.AWS.URL,
            MUSIC: this.$store.state.music,
            main: [],
            sub: [],
            sns: [],

            audio: new Audio(this.AWSURL+this.MUSIC),
            isPlaying: false,
        }
    },
    mounted(){
        this.menu();
        this.get_SNS();
    },
    methods:{
        slideOpenFn(){
            var slideMenu = document.getElementById('slide-menu');
            slideMenu.classList.toggle('on')
        },
        menu() {
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/home/menu`, {}
            ).then(
                res => {
                    if(res.status == 200) {
                        this.main = res.data.main;
                        this.sub = res.data.sub;
                    }
                }
            )
        },
        get_SNS() {            
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/home/sns`, {}
            ).then(
                res => {
                    if(res.status == 200) {
                        this.sns = res.data.info;
                    }
                }
            )
        },
        music_Play(){    
            this.isPlaying = true;     

            this.audio = new Audio(this.AWSURL+this.MUSIC)
            this.audio.loop = true;
            this.audio.play();
        },
        music_Stop(){
            this.isPlaying = false;     
            this.audio.pause();
        }
    }
}
</script>
<style scoped>
.logo-img {
    height: 85px;
    content: url('../../assets/icons/ikan_white.png');
}
.icon-header {
    line-height: 90px;
}
/* .sound-ul {
    position: absolute;
    right: 5%;    
    top: 10%;
    z-index: 3;
} */
.max-small {
    width: auto; 
    height: auto;
    max-width: 30px;
    max-height: 25px;
}

.sound-ul{
    display: flex;
    align-items: center;
    height: 100%;
}
.sound-ul>li{
    display: flex;
    border-radius: 50%;
    /* background-color: red; */
    width: 33px;
    height: 33px;
    align-items: center;
    justify-content: center;
}
.sound-ul .icon-header{
    line-height: 0 !important;
}
.sound-ul>li>a>i {
    color: white;
    font-size: 28px;
}


#gnb .active {
    border-bottom: 3px solid #47a6b9;
}

#gnb>li:hover>a {
  color: #5CAAEF;
}

#gnb a:before {
  content: '';
  border-bottom: solid 2px #5CAAEF;
  position: absolute;
  bottom: 0; left: 0;
  width: 100%;  
  opacity: 0;
}

#gnb a:hover:before {
  opacity: 1.0;
}

#gnb a, #gnb a:before {
  -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
}

@media (max-width: 1200px){
    .inner {
        background: '';
    }

    .logo-img {
        height: 100%;
        content: url('../../assets/icons/ikan_black.png');
    }

    #sns-ico {
        display: none;
    }

    .icon-header {
        line-height: 0px;
    }

    .sound-ul {
        position: absolute;
        top: 50%;
        margin-top: -17px;
        right: 55px;
        display: block !important;
        width: 25px;
        height: 16px !important;
    }
    .sound-ul i{
        font-size: 21px !important;
    }
    .sound-ul>li>a>i {
        color: black;
    }

}
</style>