<template>
<div>
    <div id="slide-menu">
        <div class="tit">
            <i class="fal fa-times fa-2x" @click="slideCloseFn()"></i>
        </div>
        <ul id="gnb-mo">
            <li v-for="(item, index) in main" :key="index" :class="{'active':subMenu==index}">
                <a href="#" @click="subMenu=index">{{item.title}}</a>
                <ul v-show="subMenu==index">
                    <li v-for="(jtem,key) in sub" :key="key" @click="slideCloseFn()">
                        <router-link :to="jtem.content" v-if="jtem.h_no == item.no">
                            {{jtem.title}}
                        </router-link>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</div>
</template>
<script>
export default {
    data(){
        return{
            subMenu:-1,
            main: [],
            sub: [],
        }
    },
    mounted(){
        this.menu();
    },
    methods:{
        slideCloseFn(){
            var button = document.getElementById('slide-menu');
            button.classList.toggle('on')
        },        
        menu() {
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/home/menu`, {}
            ).then(
                res => {
                    if(res.status == 200) {
                        this.main = res.data.main;
                        this.sub = res.data.sub;
                    }
                }
            )
        }
    }
}
</script>

<style scoped>
/* #topNav{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: #fff;
    width: 100%;
} */
.tit{
    display: flex;
    justify-content: flex-end;
    padding: 0.7em 1em;
    box-sizing: border-box;
}
</style>