<template>    
<footer id="footer">
	<div class="fnbWrap">
		<div class="inner">

			<!-- fnb -->
			<ul id="fnb">
				<li><router-link to="/company/aboutus">회사소개</router-link></li>
				<li v-for="(item, index) in term" :key="index"><router-link :to="item.site">{{item.title}}</router-link></li>
			</ul>

		</div>
	</div>
	<div class="partner">
		<ul>
			<li>
				<img src="../../assets/images/partner/hdlc.jpg" alt="">
			</li>
			<!-- <li v-for="item in partners.slice(0,5)" :key="'no'+item">
				<img :src="item.src" alt="">
			</li> -->
		</ul>
	</div>
	<div class="ftWrap">

		<!-- address -->
		<address>
			주소 : {{footer.postcode}} ) 주소 : {{footer.address}}<hr>
            대표번호 : {{footer.tel}}<hr>
            팩스 : {{footer.fax}}<br>
            
			<span class="copyright">COPYRIGHT(C) 2022 아이칸. CO.LTD ALL RIGHT RESERVED.</span>
		</address>

        <!-- SNS Channels (지우지 마세요. 관리페이지에서 설정한 경우에만 자동 노출)-->
        
		<!-- family site
		<div id="ft-fam">
			<a href="#">Family site</a>
			<ul>
				<li><a href="#" target="_blank">Naver</a></li>
				<li><a href="#" target="_blank">Daum</a></li>
			</ul>
		</div> -->

        <!-- body top button -->
        <a href="#" id="gotop">Go Top</a>

	</div>
</footer>
</template>
<script>
export default {
    data() {
		return {
			footer: {},
			term: [],
			partners:[
				{
					src:'../../assets/images/partner/hdlc.jpg'
				},
				{
					src:'https://via.placeholder.com/100x45'
				},
				{
					src:'https://via.placeholder.com/100x45'
				}
			]
		}
	},
	mounted() {
		this.get_footer();
		this.get_term();
	},
	methods: {
		get_footer() {
			const api = this.$store.state.api;            
            this.$http.post(`${api}/member/home/footer`, {}
            ).then(
                res => {
                    if(res.status == 200) {
                        this.footer = res.data.info;
                    }
                }
            )
		},
		get_term() {
			const api = this.$store.state.api;            
            this.$http.post(`${api}/member/home/term`, {}
            ).then(
                res => {
                    if(res.status == 200) {
                        this.term = res.data.info;
                    }
                }
            )
		}
	},

}
</script>
<style>
	#footer {
		width: 100%;
	}

	.partner>ul{
		padding: 45px 0 0;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 30px;
	}
	.partner img{
		height: 45px;
		object-fit: cover;
		filter: grayscale(100%);
	}
</style>